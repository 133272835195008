<template>
  <v-app class="bg-login" id="auth">
    <loading :loading="loading" />
    <v-main>
      <v-container class="login-container" fluid>
        <v-row align-content="center" class="login-container__row">
          <v-col
            class="login-container__col hidden-sm-and-down"
            :class="handlePublication() ? ' left__publication' : 'left__av'"
            :style="!loading ? background : ''"
            cols="12"
            md="6"
          >
            <div v-if="handlePublication()" class="publication__text">
              <div v-html="publication.content" />
            </div>
            <div v-else class="left__text">
              <div v-if="!broker.id  && !loading">
                <p class="uppercase">{{ $t('login_knowledge') }}</p>
                <p class="uppercase">{{ $t('login_&_reliability') }}</p>
              </div>
            </div>
          </v-col>
          <!-- FORM LOGIN -->
          <v-col v-if="!loading" class="login-container__col right" cols="12" md="6">
            <v-row>
              <v-col cols="8" offset="2">
                <div
                  class="logo-container d-flex flex-column justify-center align-center"
                >
                  <img
                    :src="logo"
                    :alt="altLogo"
                    :class="{'logo-container__image': !broker.id}"
                  />
                  <p
                    class="logo-container__text"
                    v-if="this.correoSend === false"
                  >
                    {{ $t('login_password_message') }}
                  </p>
                </div>
              </v-col>
              <v-col cols="10" offset="1">
                <v-card rounded elevation="0" v-if="this.correoSend === false">
                  <v-card-text>
                    <ValidationObserver ref="observer" v-slot="{ invalid }">
                      <form @submit.prevent="handleSubmit">
                        <v-row class="px-md-5">
                          <v-col cols="12">
                            <!-- EMAIL FIELD -->
                            <ValidationProvider
                              :name="$t('login_email')"
                              rules="required|email"
                              v-slot="{ errors }"
                            >
                              <v-text-field
                                :label="$t('login_email')"
                                v-model="user.email"
                                :hint="$t('login_email_hint')"
                                autofocus
                                :disabled="disabled == 1"
                                :error-messages="errors[0]"
                              />
                            </ValidationProvider>
                          </v-col>
                          <v-col
                            cols="12"
                            class="d-flex justify-space-between flex-row align-baseline"
                          >
                            <v-spacer></v-spacer>
                            <language-menu class="mx-auto" />
                          </v-col>
                        </v-row>
                        <v-row class="mb-5">
                          <v-col cols="12">
                            <v-row justify="center">
                              <vue-recaptcha
                                v-if="recaptchaShow"
                                sitekey="Your key here"
                                :loadRecaptchaScript="true"
                              ></vue-recaptcha>
                            </v-row>
                          </v-col>
                        </v-row>
                        <v-row
                          class="d-flex flex-column-reverse flex-md-row justify-center align-center"
                          dense
                        >
                          <v-col
                            class="d-flex flex-row justify-center align-center"
                            cols="12"
                            sm="6"
                          >
                            <v-btn
                              class="btn-cancel"
                              outlined
                              color="primary"
                              :to="{ name: 'Login', query: { broker: $route.query.broker } }"
                              rounded
                              >{{ $t('btn_cancel') }}</v-btn
                            >
                          </v-col>
                          <v-col
                            class="d-flex flex-row justify-center align-center"
                            cols="12"
                            sm="6"
                          >
                            <v-btn
                              color="primary"
                              type="submit"
                              :class="{
                                'login-button': !broker.id
                              }"
                              rounded
                              :loading="loading"
                              :disabled="invalid"
                              >{{ $t('btn_reestablish') }}</v-btn
                            >
                          </v-col>
                        </v-row>
                      </form>
                    </ValidationObserver>
                  </v-card-text>
                </v-card>
                <v-card rounded elevation="0" class="pa-6" v-else>
                  <v-card-text>
                    <v-row justify="center">
                      <span class="logo-container__text">
                        {{ message }}
                      </span>
                      <span class="logo-container__text_two mt-4">
                        {{ $t('login_in_case') }}
                        <a v-on:click="handleSubmit()" class="mx-1"
                          >{{ $t('login_click_here') }}
                        </a>
                        {{ $t('login_send_again') }}
                      </span>
                    </v-row>
                    <v-row justify="center" class="mt-8">
                      <v-btn
                        color="primary"
                        type="submit"
                        :class="{
                          'login-button': !broker.id
                        }"
                        :to="{ name: 'Login', query: { broker: $route.query.broker } }"
                        rounded
                        >{{ $t('login_login') }}</v-btn
                      >
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import UserService from '@/services/user'
import VueRecaptcha from 'vue-recaptcha'
import SettingsService from '@/services/setting'
import PublicSettingService from '@/services/publicSetting'
import BrokerService from '@/services/broker'
import LanguageMenu from '@/components/commons/LanguageMenu'
import Loading from '@/components/commons/Loading'
import { mapState } from 'vuex'

export default {
  components: { VueRecaptcha, LanguageMenu, Loading },
  data() {
    return {
      disabled: 0,
      loading: false,
      loadingCreated: false,
      correoSend: false,
      message: null,
      user: {
        email: ''
      },
      recaptchaShow: false,
      broker: {},
      publication: {
        date: null,
        content: null
      },
      publicSettings: [],
      whiteBranding: null
    }
  },
  computed: {
    ...mapState('language', ['idiom']),
    
    currentDate() {
      return this.$date().format('YYYY-MM-DD')
    },

    logo() {
      return this.broker.id ? `data:image/png;base64,${this.broker.img_desktop}` : require('@/assets/AV_002_logo.jpg')
    },

    altLogo() {
      return this.broker.id ? `${this.broker.name} Logo` : 'AV Agil Logo'
    },

    background() {
      return this.broker.id && this.broker.img_background ? `background-image: url('${this.broker.img_background}')` : `background-image: url('${require('@/assets/AV_001_inicio_sinletras.jpg')}')`
    }
  },
  watch: {
    idiom(newVal) {
      const content = this.publicSettings.find(
        item => item.key == `publications_content_${newVal.code}`
        )?.value
      this.$set(this.publication, 'content', content ?? null) 
    }
  },
  async created() {
    this.loading = true
    try {
      await this.getPublicSettings()
      const broker = this.$route.query.broker
      if (broker && this.whiteBranding) {
        await this.getBrokerInfo(broker)
      }
    } catch (error) {
      console.log(error)
    } finally {
      this.loading = false
    }   
  },
  methods: {
    async handleSubmit() {
      this.loading = true
      this.correoSend = false
      this.disabled = 1
      try {
        var data = await UserService.sendEmailConfirmation(
          this.user.email,
          this.idiom.code
        )
        this.correoSend = true
        this.message = data.message
      } catch (e) {
        this.$dialog.notify.error(e)
        this.user.email = ''
        this.correoSend = false
      } finally {
        this.loading = false
        this.disabled = 0
      }
    },
    async getSettings() {
      const { data } = await SettingsService.fetch({
        'filter[key]': 'recaptcha'
      })
      this.recaptchaShow = data.data[0].value
    },

    async getBrokerInfo(code) {
      try {
        const { data } = await BrokerService.getBrokerInfo(code)
        this.broker = data
      } catch (e) {
        console.log(e);
      } 
    },

    async getPublicSettings() {
      const { data } = await PublicSettingService.fetch({})
      this.publication.date = data.find(item => item.key == 'publications_date')?.value
      this.publication.content = data.find(item => item.key == `publications_content_${this.idiom.code}`)?.value
      this.whiteBranding = data.find(item => item.key == 'white_branding')?.value

      this.publicSettings = data
    },

    handlePublication() {
      return this.handlePublicationDate() 
        && this.handlePublicationContent() 
        && !this.broker.id 
        && !this.loading
    },

    handlePublicationDate() {
      return (this.publication.date !== '' && this.publication.date !== null) && this.publication.date >= this.currentDate 
    },

    handlePublicationContent() {
      return this.publication.content !== null && this.publication.content !== ''
    },
  }
}
</script>
<style>
#auth label.v-label {
  transform: translateY(-18px) scale(.75);
}
</style>
<style scoped>
.theme--light.v-application.bg-login {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  z-index: 0;
  background: rgb(255, 255, 255);
  background-size: cover !important;
  background-repeat: no-repeat;
  /* font-family: Lato; */
}
.login-container {
  min-height: 100vh;
}
.login-container__row {
  min-height: 100vh;
}
.login-container__col {
  min-height: 100vh;
}
.left__av {
  background-size: cover;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  height: 100vh;
}

.left__publication {
  background-image: url('../../assets/AV_004_inicio_portal_clientes_sinletra.jpg');
  background-size: cover;
  display: flex;
  align-items: center;
  height: 100vh;
  padding: 50px;
}
.left__text {
  width: 50px;
  margin-left: -200px;
  text-align: left;
  font: normal normal 300 44px/52px Lato !important;
  letter-spacing: -1.1px !important;
  color: #0294de !important;
  opacity: 1;
}

.publication__text {
  color: white;
  width: 100vh;
}

.right {
  display: flex;
  align-items: center;

  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
}
.form {
  display: flex;
  flex-direction: column;
}
.links {
  display: flex;
  justify-content: space-between;
}
.links a {
  font: normal normal normal 16px/27px Lato;
  letter-spacing: 0px;
  color: #00559e;
  opacity: 1;
  text-decoration: none;
}
.login-button {
  min-width: 140px !important;
  background: transparent linear-gradient(112deg, #00559e 0%, #0090df 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 6px 10px 14px #00000029;
  border-radius: 28px;
  opacity: 1;
}
.btn-cancel {
  min-width: 140px !important;
}
.logo-container__text {
  margin-top: 20px;
  text-align: center;
  font: normal normal normal 18px/14px Lato;
  letter-spacing: 0px;
  color: #8990ad;
  opacity: 1;
}
.logo-container__image {
  width: 85%;
}
.bg-login {
  font: normal normal normal 16px/27px Lato;
}
.form label {
  font: normal normal normal 12px/15px Lato;
  letter-spacing: 0px;
}

.uppercase {
  text-transform: uppercase;
}

@media (max-width: 979px) {
  
  .logo-container__text {
    font: normal normal normal 16px/18px Lato;
  }
  .logo-container__text_two {
    font: normal normal normal 16px/18px Lato;
  }
  .text-left {
    font: normal normal normal 12px/27px Lato;
  }
}
</style>